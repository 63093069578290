import React from 'react';
import PropTypes from 'prop-types';
import Loader from '../Loader';

const withEndlessScroll = Component => {
  class EndlessScroll extends React.Component {
    componentDidMount() {
      window.addEventListener('scroll', this.handleScroll, false);
    }

    componentWillUnmount() {
      window.removeEventListener('scroll', this.handleScroll, false);
    }

    handleScroll = () => {
      const { onLoadMore, loading, isStopLoad, forwardedRef } = this.props;
      const node = forwardedRef.current;
      const offset = window.innerHeight * 2;

      if (this.isInViewport(node, offset) && !loading && !isStopLoad) {
        onLoadMore();
      }
    };

    // eslint-disable-next-line class-methods-use-this
    isInViewport = (el, offset = 0) => {
      if (!el) return false;
      const { top } = el.getBoundingClientRect();
      const { height } = el.getBoundingClientRect();

      return (
        top + height + offset >= 0 &&
        top + height - offset <= window.innerHeight
      );
    };

    render() {
      const { loading, items, isStopLoad } = this.props;

      return (
        <>
          <Component {...this.props} />
          <Loader
            loading={loading && !!items.length && !isStopLoad}
            optionalClass="endless-loader"
          />
        </>
      );
    }
  }

  EndlessScroll.propTypes = {
    t: PropTypes.func,
    items: PropTypes.array,
    loading: PropTypes.bool,
    onLoadMore: PropTypes.func,
    isStopLoad: PropTypes.bool,
    forwardedRef: PropTypes.object
  };

  function forwardRef(props, ref) {
    return <EndlessScroll {...props} forwardedRef={ref} />;
  }

  const name = Component.displayName || Component.name;
  forwardRef.displayName = `endlessScroll(${name})`;

  return React.forwardRef(forwardRef);
};

export default withEndlessScroll;
